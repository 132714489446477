@import url("https://fonts.googleapis.com/css2?family=JetBrains+Mono:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap");
*, *:before, *:after {
  box-sizing: border-box;
}

html, body, div, span, object, iframe, figure, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, code, em, img, small, strike, strong, sub, sup, tt, b, u, i, ol, ul, li, fieldset, form, label, table, caption, tbody, tfoot, thead, tr, th, td, main, canvas, embed, footer, header, nav, section, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  text-size-adjust: none;
}

footer, header, nav, section, main {
  display: block;
}

body {
  line-height: 1;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after, q:before, q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

input {
  -webkit-appearance: none;
  border-radius: 0;
}

/*
  @mixin theme($property, $key, $inlineExtra:null, $postExtra:null)
  Assigns a property a theme value for each defined theme.

  Example usage:
    .mySelector {
      padding: 6px;
      @include theme(background-color, backgroundLight);
      @include theme(border, borderDark, 1px solid);
    }

  sass generated equivalent:
    .mySelector {
      padding: 6px;

      :global(.light) & {
        border: 1px solid color(woodsmoke);
        border: 1px solid var(--theme-light-borderDark, color(woodsmoke));
      }
      :global(.light) & {
        background-color: color(alabaster);
        background-color: var(--theme-light-backgroundLight, color(alabaster));
      }

      :global(.dark) & {
        border: 1px solid color(alabaster);
        border: 1px solid var(--theme-dark-borderDark, color(alabaster));
      }
      :global(.dark) & {
        background-color: color(woodsmoke);
        background-color: var(--theme-dark-backgroundLight, color(woodsmoke));
      }
    }

  browser output:
    .mySelector {
      padding: 6px;
    }
    .light .mySelector {
      border: 1px solid #141519;
      border: 1px solid var(--theme-light-borderDark, #141519);
    }
    .light .mySelector {
      background-color: #FCFCFC;
      background-color: var(--theme-light-backgroundLight, #FCFCFC);
    }

    .dark .mySelector {
      border: 1px solid #FCFCFC;
      border: 1px solid var(--theme-dark-borderDark, #FCFCFC);
    }
    .dark .mySelector {
      background-color: #141519;
      background-color: var(--theme-dark-backgroundLight, #141519);
    }
*/
/*
  @mixin generateThemeMappings(themeName: string, themeMap: map)
  helper function for generating list of theme variables and adding to existing map.
  This will add a new theme to the themes array and the theme color list.
*/
/*
  @mixin generateThemeVariables
  Auto-generates the entire list of theme variables for use in var() statements.
  Really should only be called in the html selector at the app root.
*/
/*
  @mixin theme($property, $key, $inlineExtra:null, $postExtra:null)
  Assigns a property a theme value for each defined theme.

  Example usage:
    .mySelector {
      padding: 6px;
      @include theme(background-color, backgroundLight);
      @include theme(border, borderDark, 1px solid);
    }

  sass generated equivalent:
    .mySelector {
      padding: 6px;

      :global(.light) & {
        border: 1px solid color(woodsmoke);
        border: 1px solid var(--theme-light-borderDark, color(woodsmoke));
      }
      :global(.light) & {
        background-color: color(alabaster);
        background-color: var(--theme-light-backgroundLight, color(alabaster));
      }

      :global(.dark) & {
        border: 1px solid color(alabaster);
        border: 1px solid var(--theme-dark-borderDark, color(alabaster));
      }
      :global(.dark) & {
        background-color: color(woodsmoke);
        background-color: var(--theme-dark-backgroundLight, color(woodsmoke));
      }
    }

  browser output:
    .mySelector {
      padding: 6px;
    }
    .light .mySelector {
      border: 1px solid #141519;
      border: 1px solid var(--theme-light-borderDark, #141519);
    }
    .light .mySelector {
      background-color: #FCFCFC;
      background-color: var(--theme-light-backgroundLight, #FCFCFC);
    }

    .dark .mySelector {
      border: 1px solid #FCFCFC;
      border: 1px solid var(--theme-dark-borderDark, #FCFCFC);
    }
    .dark .mySelector {
      background-color: #141519;
      background-color: var(--theme-dark-backgroundLight, #141519);
    }
*/
/*
  @mixin generateThemeMappings(themeName: string, themeMap: map)
  helper function for generating list of theme variables and adding to existing map.
  This will add a new theme to the themes array and the theme color list.
*/
/*
  @mixin generateThemeVariables
  Auto-generates the entire list of theme variables for use in var() statements.
  Really should only be called in the html selector at the app root.
*/
.animated, .navbar .right ul.links li a.button, .navbar .right ul.links-mobile li a.button, .navbar .right ul.links li a, .navbar .right ul.links-mobile li a, .switch .slider {
  -webkit-transition: color 200ms ease;
  transition: color 200ms ease;
}

html, body {
  height: 100%;
}

html {
  --theme-light-mainBackground: #ebd6d2;
  --theme-dark-mainBackground: #352a2d;
  --theme-light-textColor: #352a2d;
  --theme-dark-textColor: #ebd6d2;
  --theme-light-mutedTextColor: #604c51;
  --theme-dark-mutedTextColor: #c2b2af;
  --theme-light-oppositeColor: #000000;
  --theme-dark-oppositeColor: #FFFFFF;
  --theme-light-oppositeTextColor: #FFFFFF;
  --theme-dark-oppositeTextColor: #000000;
  --theme-light-accentColor: #846a6d;
  --theme-dark-accentColor: #ebd6d2;
  --theme-light-linkColor: #ff2946;
  --theme-dark-linkColor: #ffc629;
  --theme-light-borderColor: #846a6d;
  --theme-dark-borderColor: #846a6d;
  --theme-light-primary: #ffc629;
  --theme-dark-primary: #ffc629;
  --theme-light-secondary: #ff2946;
  --theme-dark-secondary: #ff2946;
  font-family: "JetBrains Mono", monospace;
  background-color: #352a2d;
  font-size: 16px;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: 2rem;
  min-height: 100%;
  padding: 0;
  padding: 0;
  margin: 0;
  margin: 0;
}
.light body {
  color: #352a2d;
  color: var(--theme-light-textColor, #352a2d);
}

.dark body {
  color: #ebd6d2;
  color: var(--theme-dark-textColor, #ebd6d2);
}

#app {
  min-height: 100%;
}

.light #app {
  background-color: #ebd6d2;
  background-color: var(--theme-light-mainBackground, #ebd6d2);
}

.dark #app {
  background-color: #352a2d;
  background-color: var(--theme-dark-mainBackground, #352a2d);
}

.switch {
  display: inline-block;
  position: relative;
  height: 34px;
  width: 60px;
}
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}
.switch input:focus, .switch input:active {
  outline: 0;
}
.switch input:checked + .slider {
  background-color: #ffc629;
}
.switch input:focus + .slider {
  box-shadow: 0 0 1px #ffc629;
}
.switch input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}
.switch .slider {
  background-color: rgba(0, 0, 0, 0.2);
  position: absolute;
  cursor: pointer;
  transition: 0.4s;
  width: 4rem;
  bottom: 0;
  right: 0;
  left: 0;
  top: 0;
}
.switch .slider::before {
  background-color: white;
  position: absolute;
  transition: 0.4s;
  height: 1.5rem;
  bottom: 0.3rem;
  width: 1.5rem;
  left: 0.4rem;
  content: "";
}
.switch .slider.round {
  border-radius: 1.5rem;
}
.switch .slider.round::before {
  border-radius: 50%;
}

.container {
  padding-right: 3rem;
  padding-left: 3rem;
  margin-right: auto;
  margin-left: auto;
  max-width: 1280px;
  width: 100%;
}

.btn {
  background-color: transparent;
  border-radius: 2rem;
  text-decoration: none;
  display: inline-block;
  padding: 1rem 2rem;
  line-height: 1rem;
  font-size: medium;
  font-weight: 700;
  cursor: pointer;
}
.light .btn {
  border: 2px solid #846a6d;
  border: 2px solid var(--theme-light-borderColor, #846a6d);
}

.dark .btn {
  border: 2px solid #846a6d;
  border: 2px solid var(--theme-dark-borderColor, #846a6d);
}

.light .btn {
  color: #352a2d;
  color: var(--theme-light-textColor, #352a2d);
}

.dark .btn {
  color: #ebd6d2;
  color: var(--theme-dark-textColor, #ebd6d2);
}

.btn:hover {
  box-shadow: 0 4px 22px 0 rgba(132, 106, 109, 0.25);
}
.light .btn:hover {
  background-color: #846a6d;
  background-color: var(--theme-light-borderColor, #846a6d);
}

.dark .btn:hover {
  background-color: #846a6d;
  background-color: var(--theme-dark-borderColor, #846a6d);
}

.light .btn:hover {
  color: #FFFFFF;
  color: var(--theme-light-oppositeTextColor, #FFFFFF);
}

.dark .btn:hover {
  color: #000000;
  color: var(--theme-dark-oppositeTextColor, #000000);
}

.btn.primary {
  background: linear-gradient(90deg, #ffc629 0%, #ff2946 100%);
}
.light .btn.primary {
  border: 2px solid #352a2d;
  border: 2px solid var(--theme-light-textColor, #352a2d);
}

.dark .btn.primary {
  border: 2px solid #ebd6d2;
  border: 2px solid var(--theme-dark-textColor, #ebd6d2);
}

.light .btn.primary {
  background-color: #ffc629;
  background-color: var(--theme-light-primary, #ffc629);
}

.dark .btn.primary {
  background-color: #ffc629;
  background-color: var(--theme-dark-primary, #ffc629);
}

.light .btn.primary {
  color: #352a2d;
  color: var(--theme-light-textColor, #352a2d);
}

.dark .btn.primary {
  color: #ebd6d2;
  color: var(--theme-dark-textColor, #ebd6d2);
}

.btn.primary:hover {
  box-shadow: 0 4px 22px 0 rgba(255, 198, 41, 0.25);
  background: none;
}
.light .btn.primary:hover {
  border: 2px solid #ffc629;
  border: 2px solid var(--theme-light-primary, #ffc629);
}

.dark .btn.primary:hover {
  border: 2px solid #ffc629;
  border: 2px solid var(--theme-dark-primary, #ffc629);
}

.light .btn.primary:hover {
  background-color: #ffc629;
  background-color: var(--theme-light-primary, #ffc629);
}

.dark .btn.primary:hover {
  background-color: #ffc629;
  background-color: var(--theme-dark-primary, #ffc629);
}

.light .btn.primary:hover {
  color: #FFFFFF;
  color: var(--theme-light-oppositeTextColor, #FFFFFF);
}

.dark .btn.primary:hover {
  color: #000000;
  color: var(--theme-dark-oppositeTextColor, #000000);
}

.btn.secondary {
  background-color: rgba(0, 0, 0, 0.2);
}
.light .btn.secondary {
  border: 2px solid #ff2946;
  border: 2px solid var(--theme-light-secondary, #ff2946);
}

.dark .btn.secondary {
  border: 2px solid #ff2946;
  border: 2px solid var(--theme-dark-secondary, #ff2946);
}

.btn.secondary:hover {
  box-shadow: 0 4px 22px 0 rgba(255, 41, 70, 0.25);
}
.light .btn.secondary:hover {
  color: #352a2d;
  color: var(--theme-light-textColor, #352a2d);
}

.dark .btn.secondary:hover {
  color: #ebd6d2;
  color: var(--theme-dark-textColor, #ebd6d2);
}

.logo {
  width: 54px;
  height: 54px;
}

/*
  @mixin theme($property, $key, $inlineExtra:null, $postExtra:null)
  Assigns a property a theme value for each defined theme.

  Example usage:
    .mySelector {
      padding: 6px;
      @include theme(background-color, backgroundLight);
      @include theme(border, borderDark, 1px solid);
    }

  sass generated equivalent:
    .mySelector {
      padding: 6px;

      :global(.light) & {
        border: 1px solid color(woodsmoke);
        border: 1px solid var(--theme-light-borderDark, color(woodsmoke));
      }
      :global(.light) & {
        background-color: color(alabaster);
        background-color: var(--theme-light-backgroundLight, color(alabaster));
      }

      :global(.dark) & {
        border: 1px solid color(alabaster);
        border: 1px solid var(--theme-dark-borderDark, color(alabaster));
      }
      :global(.dark) & {
        background-color: color(woodsmoke);
        background-color: var(--theme-dark-backgroundLight, color(woodsmoke));
      }
    }

  browser output:
    .mySelector {
      padding: 6px;
    }
    .light .mySelector {
      border: 1px solid #141519;
      border: 1px solid var(--theme-light-borderDark, #141519);
    }
    .light .mySelector {
      background-color: #FCFCFC;
      background-color: var(--theme-light-backgroundLight, #FCFCFC);
    }

    .dark .mySelector {
      border: 1px solid #FCFCFC;
      border: 1px solid var(--theme-dark-borderDark, #FCFCFC);
    }
    .dark .mySelector {
      background-color: #141519;
      background-color: var(--theme-dark-backgroundLight, #141519);
    }
*/
/*
  @mixin generateThemeMappings(themeName: string, themeMap: map)
  helper function for generating list of theme variables and adding to existing map.
  This will add a new theme to the themes array and the theme color list.
*/
/*
  @mixin generateThemeVariables
  Auto-generates the entire list of theme variables for use in var() statements.
  Really should only be called in the html selector at the app root.
*/
.navbar {
  align-items: center;
  padding: 2rem 3rem;
  display: flex;
}
.navbar .left {
  margin-right: auto;
}
.navbar .left a.brand {
  text-decoration: none;
  align-items: center;
  display: block;
  display: flex;
}
.navbar .left a.brand .logo {
  margin-right: auto;
}
.navbar .left a.brand .title {
  text-transform: uppercase;
  text-decoration: none;
  letter-spacing: 2px;
  margin-left: 1.5rem;
  font-size: 1.5rem;
  font-weight: 800;
}
.light .navbar .left a.brand .title {
  color: #352a2d;
  color: var(--theme-light-textColor, #352a2d);
}

.dark .navbar .left a.brand .title {
  color: #ebd6d2;
  color: var(--theme-dark-textColor, #ebd6d2);
}

.navbar .left a.brand .title:hover {
  text-decoration: underline;
}
.light .navbar .left a.brand .title:hover {
  color: #ff2946;
  color: var(--theme-light-linkColor, #ff2946);
}

.dark .navbar .left a.brand .title:hover {
  color: #ffc629;
  color: var(--theme-dark-linkColor, #ffc629);
}

.navbar .right ul.links, .navbar .right ul.links-mobile {
  flex-direction: row;
  align-items: center;
  display: flex;
}
.navbar .right ul.links li:not(:last-child), .navbar .right ul.links-mobile li:not(:last-child) {
  margin-right: 1rem;
}
.navbar .right ul.links li a, .navbar .right ul.links-mobile li a {
  text-decoration: none;
  letter-spacing: 1px;
  font-weight: 500;
  font-size: 1rem;
}
.light .navbar .right ul.links li a, .navbar .right ul.links-mobile li a {
  color: #352a2d;
  color: var(--theme-light-textColor, #352a2d);
}

.dark .navbar .right ul.links li a, .navbar .right ul.links-mobile li a {
  color: #ebd6d2;
  color: var(--theme-dark-textColor, #ebd6d2);
}

.navbar .right ul.links li a:hover:not(.button), .navbar .right ul.links-mobile li a:hover:not(.button) {
  text-decoration: underline;
}
.light .navbar .right ul.links li a:hover:not(.button), .navbar .right ul.links-mobile li a:hover:not(.button) {
  color: #ff2946;
  color: var(--theme-light-linkColor, #ff2946);
}

.dark .navbar .right ul.links li a:hover:not(.button), .navbar .right ul.links-mobile li a:hover:not(.button) {
  color: #ffc629;
  color: var(--theme-dark-linkColor, #ffc629);
}

.navbar .right ul.links li a.button, .navbar .right ul.links-mobile li a.button {
  border-radius: 2rem;
  text-transform: uppercase;
  background: transparent;
  padding: 0.5rem 1rem;
  flex-direction: row;
  align-items: center;
  font-weight: 700;
  display: flex;
}
.light .navbar .right ul.links li a.button, .navbar .right ul.links-mobile li a.button {
  border: 2px solid #ff2946;
  border: 2px solid var(--theme-light-linkColor, #ff2946);
}

.dark .navbar .right ul.links li a.button, .navbar .right ul.links-mobile li a.button {
  border: 2px solid #ffc629;
  border: 2px solid var(--theme-dark-linkColor, #ffc629);
}

.light .navbar .right ul.links li a.button, .navbar .right ul.links-mobile li a.button {
  color: #ff2946;
  color: var(--theme-light-linkColor, #ff2946);
}

.dark .navbar .right ul.links li a.button, .navbar .right ul.links-mobile li a.button {
  color: #ffc629;
  color: var(--theme-dark-linkColor, #ffc629);
}

.navbar .right ul.links li a.button:hover, .navbar .right ul.links-mobile li a.button:hover {
  background: linear-gradient(90deg, #ffc629 0%, #ff2946 100%);
  border-color: #FFFFFF;
  color: #FFFFFF;
}
.navbar .right ul.links li a.button:hover .icon, .navbar .right ul.links-mobile li a.button:hover .icon {
  border: 2px solid #FFFFFF;
  background-color: #FFFFFF;
  border-radius: 50%;
}
.navbar .right ul.links li a.button .icon, .navbar .right ul.links-mobile li a.button .icon {
  margin-right: 0.5rem;
  height: 1rem;
}
.navbar .right ul.links li .switch, .navbar .right ul.links-mobile li .switch {
  position: relative;
}
.navbar .right ul.links li .switch .slider, .navbar .right ul.links-mobile li .switch .slider {
  background-color: rgba(0, 0, 0, 0.3);
}
.navbar .right ul.links li .switch .icon, .navbar .right ul.links-mobile li .switch .icon {
  color: #FFFFFF;
}
.navbar .right ul.links li .switch .icon.left svg, .navbar .right ul.links-mobile li .switch .icon.left svg {
  position: absolute;
  left: 0.4rem;
  top: 0.4rem;
}
.navbar .right ul.links li .switch .icon.right svg, .navbar .right ul.links-mobile li .switch .icon.right svg {
  position: absolute;
  right: 0.15rem;
  top: 0.4rem;
}
@media screen and (max-width: 1280px) {
  .navbar .right ul.links {
    display: none;
  }
}
.navbar .right ul.links-mobile {
  justify-content: center;
  flex-direction: column;
  align-items: center;
  display: flex;
}
.navbar .right ul.links-mobile li:not(:last-child) {
  margin-bottom: 1rem;
}
.light .navbar .right ul.links-mobile li:not(:last-child) a {
  color: #352a2d;
  color: var(--theme-light-textColor, #352a2d);
}

.dark .navbar .right ul.links-mobile li:not(:last-child) a {
  color: #ebd6d2;
  color: var(--theme-dark-textColor, #ebd6d2);
}

.light .navbar .right ul.links-mobile li:not(:last-child) a:hover:not(.button) {
  color: #ff2946;
  color: var(--theme-light-linkColor, #ff2946);
}

.dark .navbar .right ul.links-mobile li:not(:last-child) a:hover:not(.button) {
  color: #ffc629;
  color: var(--theme-dark-linkColor, #ffc629);
}

.light .navbar .right ul.links-mobile li:not(:last-child) a.button {
  border: 2px solid #ff2946;
  border: 2px solid var(--theme-light-linkColor, #ff2946);
}

.dark .navbar .right ul.links-mobile li:not(:last-child) a.button {
  border: 2px solid #ffc629;
  border: 2px solid var(--theme-dark-linkColor, #ffc629);
}

.light .navbar .right ul.links-mobile li:not(:last-child) a.button {
  color: #ff2946;
  color: var(--theme-light-linkColor, #ff2946);
}

.dark .navbar .right ul.links-mobile li:not(:last-child) a.button {
  color: #ffc629;
  color: var(--theme-dark-linkColor, #ffc629);
}

.mobile-menu {
  display: none;
}
@media screen and (max-width: 1280px) {
  .mobile-menu {
    justify-content: center;
    align-items: center;
    position: fixed;
    display: block;
    display: flex;
    padding: 2rem;
    height: 100%;
    width: 100%;
    right: 0;
    top: 0;
  }
  .light .mobile-menu {
    background-color: #ebd6d2;
    background-color: var(--theme-light-mainBackground, #ebd6d2);
  }

  .dark .mobile-menu {
    background-color: #352a2d;
    background-color: var(--theme-dark-mainBackground, #352a2d);
  }
}
.mobile-menu .close {
  background-color: transparent;
  border: none;
  position: fixed;
  right: 2rem;
  padding: 0;
  top: 2rem;
  margin: 0;
}
.light .mobile-menu .close .icon {
  color: #846a6d;
  color: var(--theme-light-accentColor, #846a6d);
}

.dark .mobile-menu .close .icon {
  color: #ebd6d2;
  color: var(--theme-dark-accentColor, #ebd6d2);
}

.mobile-menu-open {
  background-color: transparent;
  display: none;
  border: none;
  padding: 0;
  margin: 0;
}
.light .mobile-menu-open .icon {
  color: #846a6d;
  color: var(--theme-light-accentColor, #846a6d);
}

.dark .mobile-menu-open .icon {
  color: #ebd6d2;
  color: var(--theme-dark-accentColor, #ebd6d2);
}

@media screen and (max-width: 1280px) {
  .mobile-menu-open {
    display: block;
  }
}

.home-hero {
  align-items: center;
  min-height: 80vh;
  padding-top: 2rem;
  padding-bottom: 2rem;
  display: flex;
}
.home-hero-body {
  max-width: 600px;
  width: 100%;
}
.home-hero-title {
  margin-bottom: 4rem;
  line-height: 4rem;
  font-weight: 700;
  font-size: 3rem;
  width: 100%;
}
.home-hero-subtitle {
  margin-bottom: 3rem;
  max-width: 600px;
  width: 100%;
}
.light .home-hero-subtitle {
  color: #604c51;
  color: var(--theme-light-mutedTextColor, #604c51);
}

.dark .home-hero-subtitle {
  color: #c2b2af;
  color: var(--theme-dark-mutedTextColor, #c2b2af);
}

.home-hero-cta {
  margin-bottom: 2rem;
  max-width: 600px;
}
.light .home-hero-cta {
  color: #604c51;
  color: var(--theme-light-mutedTextColor, #604c51);
}

.dark .home-hero-cta {
  color: #c2b2af;
  color: var(--theme-dark-mutedTextColor, #c2b2af);
}

.home-hero-cta .btn:not(:last-child) {
  margin-bottom: 1rem;
  margin-right: 1rem;
}
